<template>
	<!-- 产品 -需求记录单 -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="condition-box">
			<div class="state-box">
				<p>提出人</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.nickname}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="stateArr.length > 0">
						{{stateArr[stateIndex].nickname}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleStaClick">
							<a-menu-item v-for="(sta,staIndex) in statusList" :key="staIndex">{{sta}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{statusListIndex == 'all' ? '全部' : statusList[statusListIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>优先级</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleLevelClick">
							<a-menu-item v-for="(litm,lindex) in levelArr" :key="lindex">{{litm}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
					    {{levelIndex == 'all' ? '全部' : levelArr[levelIndex]}}
			        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p style="width: 60px;">创建时间</p>
				<a-range-picker
				    style="width: 400px"
				    :ranges="ranges"
				    show-time
				    format="YYYY-MM-DD HH:mm:ss"
					@change="change"/>
			</div>
			<a-button class="btn clo3" @click="search()">查询</a-button>
			<a-button class="btn clo1" @click="openRecord()" v-if="authority.add">添加</a-button>
			<a-button class="btn clo2" @click="getExport">导出</a-button>
		</div>
		<a-table 
			class="ttable" 
			:columns="columnsHe" 
			:data-source="data" 
			:scroll="{ x:1500 }" 
			bordered
			:pagination="false">
		  <template #bodyCell="{ column ,record,index}">
			<template v-if="column.title === '提出人'">
			  	{{record.user.nickname}}
			</template>
			<template v-if="column.title === '需求描述'">
				<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName" >
				    <template #title>
						<p class="ptxtp" style="color: #666666;">{{record.describe}}</p>
				    </template>
					<p class="ptxtp ellipsis-rows5">{{record.describe}}</p>
				</a-tooltip>
			</template>
			
			<template v-if="column.title === '备注' && record.remark_info.length > 0">
			  	 <!-- <a-input 
					v-model:value="record.remark" 
					placeholder="输入备注" 
					class="input-box" 
					:disabled="!record.buttons.edit"
					@blur="record.buttons.edit ? changeinput(record.id,record.remark) : false"/> -->
				<div style="max-height: 110px;overflow: hidden;">
					<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName" >
					    <template #title>
					      <p 
							class="ptxtp" 
							style="color: #333333;" 
							v-for="(ritem,rindex) in record.remark_info" 
							:key="rindex">{{ritem.nickname}}备注：<span style="color: #999999;" >{{ritem.content}}</span></p>
					    </template>
						<p 
							class="ptxtp ellipsis-rows2" 
							v-for="(ritem,rindex) in record.remark_info" 
							:key="rindex">
							{{ritem.nickname}}备注：<span style="color: #999999;" >{{ritem.content}}</span>
						</p>
					</a-tooltip>
				</div>
			</template>
			<template v-if="column.dataIndex === 'priority_text'">
				<a-button size="small" type="danger" v-if="record.priority == 'urgent'">{{record.priority_text}}</a-button>
				<a-button size="small" class="btn clo2" v-else-if="record.priority == 'commonly'">{{record.priority_text}}</a-button>
				<a-button size="small" class="btn clo1" v-else-if="record.priority == 'post'">{{record.priority_text}}</a-button>
				<p v-else>未填写</p>
			</template>
		    <template v-if="column.key === 'operation'">
				<div class="caozuo">
					<template v-if="authority.pd_index">
						<!-- 产品经理 -->
						<p
							:style="!record.buttons.to_demand ? 'color:#999999;':''" 
							@click="record.buttons.to_demand ? to_demandClick(record): false">转需求</p>	
					</template>
					<template v-if="authority.pj_index">
						<!-- 项目经理 -->
						<p
							:style="!record.buttons.proposed ? 'color:#999999;':''" 
							@click="record.buttons.proposed ? proposedClick(record.id,index): false">提产品</p>	
						<p
							:style="!record.buttons.to_task ? 'color:#999999;':''"
							@click="record.buttons.to_task ? taskJump(record) : false">转任务</p>
						<p
							:style="!record.buttons.handle ? 'color:#999999;':''"
							@click="record.buttons.handle ? goPending(record.id,index) : false">确认处理</p>	
						<p
							:style="!record.buttons.edit ? 'color:#999999;':''"
							@click="record.buttons.edit ? updateBtn(record.id,record.describe) : false">修改</p>	
						<a-popconfirm
							title="确认是否删除?"
							ok-text="确认"
							cancel-text="取消"
							:disabled="!record.buttons.del"
							@confirm="delBtn(record.id,index)">
							<p :style="!record.buttons.del ? 'color:#999999;':''">删除</p>	
						</a-popconfirm>
					</template>
					<template v-if="authority.index">
						<!-- 解决方案经理 -->
						<p
							:style="!record.buttons.edit ? 'color:#999999;':''"
							@click="record.buttons.edit ? updateBtn(record.id,record.describe) : false">修改</p>	
						<a-popconfirm
							title="确认是否删除?"
							ok-text="确认"
							cancel-text="取消"
							:disabled="!record.buttons.del"
							@confirm="delBtn(record.id,index)">
							<p :style="!record.buttons.del ? 'color:#999999;':''">删除</p>	
						</a-popconfirm>
					</template>
					
					<p @click="openRemark(record.id)">备注</p>
					
					<!-- <a-popconfirm
					    title="确认是否处理?"
					    ok-text="确认"
					    cancel-text="取消"
					    @confirm="goPending(record.id,index)"
						v-if="authority.handle">
						<p :style="record.status == 'processed' ? 'color:#999999;':''">确认处理</p>
					</a-popconfirm> -->
					<!-- <p :style="record.status == 'processed' ? 'color:#999999;':''" @click="record.status == 'processed' ? false : updateBtn(record.id,record.describe)" v-if="authority.edit">修改</p>
					<a-popconfirm
					    title="确认是否删除?"
					    ok-text="确认"
					    cancel-text="取消"
					    @confirm="delBtn(record.id,index)"
						v-if="authority.del">
					    <p>删除</p>
					</a-popconfirm> -->
				</div>
			</template>
		  </template>
		</a-table>
		<div class="documentFt" style="margin-top: 10px;">
		  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
		</div>
		
		<!-- 备注 -->
		<a-modal v-if="isRemark" v-model:visible="isRemark" title="备注" @ok="changeinput()" @cancel="cancelRemark()">
			<a-form
				class="need-box"
			    autocomplete="off"
				:scrollToFirstError='true'>
				<a-form-item>
				    <a-textarea placeholder="请输入备注" style="height: 150px;" v-model:value="remark" />
				</a-form-item>
			</a-form>
		</a-modal>
    </GModule>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  name: 'ProductRecord',
  data(){
    return {
		activeKey:0,
		stateArr:[] ,// 提出人列表
		stateIndex:0,
		statusList:{}, // 状态列表
		statusListIndex:'all',
		levelArr:{} ,// 优先级列表
		levelIndex:'all',
		columnsHe: [{
		    title: '创建时间',
		    width: 150,
		    dataIndex: 'createtime_text',
		    fixed: 'left'
		  }, {
		    title: '提出人',
		    width: 60,
		    dataIndex: 'user'
		  }, {
				title: '优先级',
				width: 50,
				dataIndex: 'priority_text'
		   }, {
		    title: '需求描述',
		    width: 250,
		    dataIndex: 'describe',
			customCell: this.renderCustomCell  //此处添加方法
		  }, {
		    title: '状态',
		    width: 85,
		    dataIndex: 'status_text'
		  }, {
		    title: '备注',
		    width: 150,
		    dataIndex: 'remark'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 170,
		}],
		data: [],
		limit:10,
		page:1,
		createtime:'' ,// 创建时间
		ranges: {
			'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
			'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
			'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
			'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
		},
		total:0 ,// 总条数
		resUrl:'',
		isRemark:false,
		remark:'' ,// 备注
		remarkId:0
    }
  },
  computed: {
  	proId(){
  		return this.$store.state.proId
  	},
	authority(){
		var data = {};
		this.$store.state.params.forEach(item=>{
			data[item.name.split('/')[2]] = true;
		})
		return data
	},
	isFresh(){
		return this.$store.state.isFresh
	},
	updateId(){
		return this.$store.state.updateId
	},
	isUpdateFresh(){
		return this.$store.state.isUpdateFresh
	}
  },
  watch:{
  	isFresh(val,oldvalue){
  		// 添加后刷新数据
  		if(val){
  			// 重置数据，刷新列表
  			this.page = 1 
  			this.createtime = ''
  			this.$store.commit('setIsFresh',0)
  			this.getList()
  		}
  	},
  	isUpdateFresh(val,oldvalue){
  		// 修改完成后回显列表
  		if(val){
  			this.getList()
			this.$store.commit('setUpdateId',null) // 重置Id
			this.$store.commit('setUpdateDescribe',"") //重置需求描述
			this.$store.commit('setIsUpdateFresh',0) 
  		}
  	},
	proId(val,oldvalue){
		if(val){
			this.getList()
		}
	}
  },
  async created() {
	  console.log(this.authority)
	  // if(this.authority.index){
		 //  for(let i in this.columnsHe){
			//   if(this.columnsHe[i].title == '备注'){
			// 	  this.columnsHe[i].width = 0
			//   }
		 //  }
	  // }
	// 获取解决方案经理/提出人
  	let res = await this.$utils.api.post({
		url:'/team/team_selectpage',
		data:{
			project_id:this.proId,
			label:this.authority.pj_index ? 'project' : this.authority.index ? 'solution' : 'project,solution'
		},
		result:1
	})
	if(res.code == 1){
		let a = [{id: 0, nickname: "全部", pid: 0}]
		this.stateArr =[...a,...res.data.list]
	}
	// 获取优先级
	let r = await this.$utils.api.get({
		url:'/demand_record/setting',
		result:1
	})
	if(r.code == 1){
		let a = {all:"全部"}
		this.levelArr = {...a,...r.data.priorityList}
		if(this.authority.pd_index){
			for(let i in r.data.statusList){
				if(i == 'pending'){
					delete r.data.statusList[i]
				}
				if(i == 'proposed'){
					r.data.statusList[i] = '待处理'
				}
			}
		}
		this.statusList = {...a,...r.data.statusList}
	}
	// 初始化列表
	this.getList()
  },
  methods:{
	  handleMenuClick(e){
		  this.stateIndex = e.key
	  },
	  handleLevelClick(e){
	  		  this.levelIndex = e.key
	  },
	  handleStaClick(e){
	  		  this.statusListIndex = e.key
	  },
	  change(date,value){
	  	// 选择创建时间
	  	if(value[0] == '' || value[1] == ''){
	  		// 创建时间要同时满足开始时间和结束时间才可以查询
	  		this.createtime = ''
	  		return
	  	}
	  	let val = value.join(' - ') // 拼接日期
	  	this.createtime = val
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
		// 请求列表
		let url = ''
		if(this.authority.pd_index){
			// 产品经理
			url = '/demand_record/pd_index'
		}
		if(this.authority.pj_index){
			// 项目经理
			url = '/demand_record/pj_index'
		}
		if(this.authority.index){
			// 解决方案经理
			url = '/demand_record/index'
		}
		this.resUrl = url
		let info = {
			limit:this.limit,
			page:this.page,
			op:{},
			filter:{}
		}
		if(this.stateArr.length > 0){
			if(this.stateArr[this.stateIndex].id){ // 提出人
				info.op['user_id'] = 'IN'
				info.filter['user_id'] = this.stateArr[this.stateIndex].id
			}
		}
		
		if(this.statusListIndex != 'all'){ // 状态
			info.op['status'] = 'IN'
			info.filter['status'] = this.statusListIndex
		}
		
		if(this.levelIndex != 'all'){ // 优先级
			info.op['priority'] = 'IN'
			info.filter['priority'] = this.levelIndex
		}
		
		if(this.createtime){
			info.op['createtime'] = 'RANGE'
			info.filter['createtime'] = this.createtime
		}
	  	let rr = await this.$utils.api.post({
	  		url:url ,
	  		data:info,
	  		result:1
	  	})
	  	if(rr.code == 1){
			if(this.authority.pd_index){
				for(let i in rr.data.rows){
					if(rr.data.rows[i].status_text === '已提产品'){
						rr.data.rows[i].status_text = '待处理'
						rr.data.rows[i].createtime_text = rr.data.rows[i].createtime_text.split(' ')[0]
					}
				}
			}
	  		this.data = rr.data.rows
			this.total = rr.data.total
	  	}
	  },
	  openRecord(){
		this.$store.commit('setUpdateId',null) // 重置Id
		this.$store.commit('setUpdateDescribe',"") //重置需求描述
	  	this.$store.commit('setIsRecordAdd',1)
	  },
	  async goPending(id,index){
		  // 去处理
		  let res = await this.$utils.api.post({
			  url:'/demand_record/handle',
			  data:{
				  ids:id
			  },
			  result:1
		  })
		  if(res.code == 1){
			  this.data[index].status = 'processed'
			  this.data[index].status_text = '已提开发'
			  this.data[index].buttons.handle = false
		  }
	  },
	  async delBtn(id,index){
	  	// 删除
	  	let res = await this.$utils.api.post({
	  		url:'/demand_record/del',
	  		data:{
	  			ids:id
	  		},
	  		result:1
	  	})
	  	if(res.code == 1){
	  		this.$utils.msgSuc('删除成功')
	  		this.data.splice(index,1)
	  	}
	  },
	  updateBtn(id,describe){
	  	// 编辑
		this.$store.commit('setIsTaskUpdate',0) //任务
	  	this.$store.commit('setIsRecordAdd',1) //弹出修改
	  	this.$store.commit('setUpdateId',id) // 修改Id
	  	this.$store.commit('setUpdateDescribe',describe) //回显
	  },
	  getExport(){
		this.$utils.api.downLoad({
			url:'/demand_record/export',
			data:{
				action_name:this.resUrl.split('/')[2]
			},
			name:'需求记录单'
		})
	  },
	  renderCustomCell(record){
		return {
			onClick: () => {
				if(this.authority.pd_index){
					return false
				}
				this.copy(record.describe) // 点击复制
			},
		}
	  },
	  async copy(text){
	  		// 复制
	  		if(text){
	  			let res = await this.$utils.api.copyText(text)
	  			this.$utils.msgSuc(res,1)
	  		}
	  	},
		openRemark(id){
			this.remarkId = id
			this.isRemark = true
			this.remark = ""
		},
		cancelRemark(){
			this.remarkId = 0
			this.isRemark = false
			this.remark = ""
		},
		async changeinput(){
			// 备注
			if(this.remark){
				let res = await this.$utils.api.post({
					url:'/demand_record/remark',
					data:{
						ids:this.remarkId,
						content:this.remark
					},
					result:1
				})
				if(res.code == 1){
					this.getList()
					this.$utils.msgSuc('已备注')
					this.cancelRemark()
				}
			}else{
				this.$utils.msgWar('请输入备注')
			}
		},
		async proposedClick(id,index){
			// 提产品
			if(this.data[index].status == 'proposed'){
				return
			}
		
			let res = await this.$utils.api.post({
				url:'/demand_record/proposed',
				data:{
					ids:id
				},
				result:1
			})
			if(res.code == 1){
				this.data[index].status = 'proposed'
				this.data[index].status_text = '已提产品'
				this.data[index].buttons.proposed = false 
				this.data[index].buttons.to_task = false
				this.data[index].buttons.handle = false
				this.data[index].buttons.edit = false
				this.data[index].buttons.del = false
			}
		},
		taskJump(record){
			// 跳转到建任务
			this.$router.push({path:'/TaskCreate',query:{isRecord:1,record_id:record.id,priority:record.priority,describe:record.describe}})
		},
		to_demandClick(record){
			// 跳转到提需求
			this.$router.push({path:'/ProductNeed',query:{isRecord:1,record_id:record.id,priority:record.priority,describe:record.describe}})
		}
  }

}
</script>

<style scoped lang="scss">
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}
.input-box{
	border: 0;
}
.mokuai{
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>